const STATUES_EN = {
  added: "Added",
  canceled: "Canceled",
  confirmed: "To come",
  ended: "Ended",
  expired: "Expired",
  paid: "Paid",
  paymentToCome: "Payment to come",
  proposed: "Proposed",
  refused: "Refused",
  scheduled: "To be processed",
  sent: "To be processed",
  started: "Started",
  to_add: "To add",
  toAdd: "To add",
  toCorrect: "To correct",
  toJustify: "To justify",
  toPay: "To pay",
  up_to_date: "Up to date",
  validationInProgress: "Validation in progress",
  waiting_for_confirmation: "To come",
  waiting_for_validation: "In progress",
} as const;

export default STATUES_EN;
