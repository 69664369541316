const STATUES_FR = {
  added: "Ajouté",
  canceled: "Annulée",
  confirmed: "À venir",
  ended: "Terminée",
  expired: "Expiré",
  paid: "Payé",
  paymentToCome: "A payer",
  proposed: "Offre",
  refused: "Refusée",
  scheduled: "À traiter",
  sent: "À traiter",
  started: "En cours",
  to_add: "À ajouter",
  toAdd: "À ajouter",
  toCorrect: "À corriger",
  toJustify: "À justifier",
  toPay: "À payer",
  up_to_date: "À jour",
  validationInProgress: "Validation en cours",
  waiting_for_confirmation: "À venir",
  waiting_for_validation: "En cours",
} as const;

export default STATUES_FR;
